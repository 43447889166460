import React, { useState } from 'react';
import { useTranslation} from "react-i18next";

const Tabs = ({ tabs }) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className=" text-xl font-medium">
      <div className="flex gap-3 pt-8">
        {tabs.map((tab, index) => (
          <button
            key={index}
            className={`px-4 py-2  ${
              index === activeTab
                ? 'border  font-bold rounded-xl bg-white shadow'
                : ' hover:bg-gray-100/50 rounded-xl border font-bold  text-title hover:text-title'
            }`}
            onClick={() => setActiveTab(index)}
          >
            {tab.title}
          </button>
        ))}
      </div>
      <div className="py-4 h-auto lg:h-[650px]">
        <ul className='flex flex-col gap-4 list-disc list-outside px-8'>
          {tabs[activeTab].items.map((item, index) => (
            <li className='py-2  rounded-xl text-2xl font-normal ' key={index}>{t(item)}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Tabs;
