import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Logo from "../svg/logo.svg";

import espaEn from "../espa-img/espa-en.png";
import espaGr from "../espa-img/espa-gr.png";
import espamoreGR from "../espa-img/espa.png";
import espamoreEN from "../espa-img/camvio_poster_en.png"

// Define the images for each language
const images = {
  en: {
    display: espaEn,
    link: espamoreEN,
  },
  gr: {
    display: espaGr,
    link: espamoreGR,
  },
};

function Dropdown({ isOpen, toggle, label, items, isBurgerOpen }) {
  const handleItemClick = () => {
    if (isOpen) {
      toggle();
    }
  };

  if (isBurgerOpen) {
    return items.map((item, index) => (
      <li key={index}>
        <Link
          to={item.to}
          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
          onClick={handleItemClick}
        >
          {item.label}
        </Link>
      </li>
    ));
  }

  return (
    <li className="relative hidden lg:block">
      <button
        type="button"
        className="inline-flex justify-center gap-x-1.5 rounded-md px-3 py-2 text-sm font-semibold text-gray-900 hover:bg-gray-50"
        onClick={toggle}
        aria-haspopup="true"
        aria-expanded={isOpen}
      >
        {label}
        <svg
          className="-mr-1 h-5 w-5 text-gray-400"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      {isOpen && (
        <div className="absolute mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
          <div className="py-1" role="menu" aria-orientation="vertical">
            {items.map((item, index) => (
              <Link
                key={index}
                to={item.to}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                role="menuitem"
                onClick={handleItemClick}
              >
                {item.label}
              </Link>
            ))}
          </div>
        </div>
      )}
    </li>
  );
}

function Nav() {
  const { t, i18n } = useTranslation();
  const isEn = i18n.language.startsWith("en");
  const [top, setTop] = useState(true);
  const [isBurgerOpen, setIsBurgerOpen] = useState(false);
  const [isDropdown1Open, setIsDropdown1Open] = useState(false);
  const [isDropdown2Open, setIsDropdown2Open] = useState(false);

  useEffect(() => {
    const scrollHandler = () => {
      setTop(window.pageYOffset <= 10);
    };
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, []);

  const toggleBurgerMenu = useCallback(() => {
    setIsBurgerOpen((prev) => !prev);
  }, []);

  const changeLanguage = () => {
    i18n.changeLanguage(isEn ? "gr" : "en");
  };

  const toggleDropdown1 = useCallback(() => {
    setIsDropdown1Open((prev) => !prev);
  }, []);

  const toggleDropdown2 = useCallback(() => {
    setIsDropdown2Open((prev) => !prev);
  }, []);

  const handleImageClick = () => {
    const link = images[isEn ? "en" : "gr"]?.link;
    if (link) {
      window.open(link, "_blank");
    }
  };

  return (
    <header
      className={`fixed w-full z-30 transition duration-300 bg-[#FFFBF6] ease-in-out ${
        !top && "backdrop-blur-sm shadow-lg"
      }`}
    >
      <div className="w-full px-5 sm:px-6">
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginRight: "30px",
          }}
          onClick={handleImageClick}
        >
         <img
  src={isEn ? espaEn : espaGr}
  alt="Language display"
  style={{ width: "500px", height: "auto", cursor: "pointer" }}
/>

        </div>
        <div className="flex items-center justify-between h-16 md:h-20 lg:mx-9 mx-auto">
          <div style={{ width: "170px" }} className="flex-shrink-0 mr-4">
            <Link to="/" className="block" aria-label="Cruip">
              <img src={Logo} alt="Camvio" />
            </Link>
          </div>
          <div className="md:hidden flex" onClick={toggleBurgerMenu}>
            <div
              className="cursor-pointer bg-center bg-contain bg-no-repeat mr-8"
              onClick={handleImageClick}
              style={{
                backgroundImage: `url(${images[isEn ? "en" : "gr"]?.display})`,
                width: "160px", // Fixed width
                paddingBottom: "56.25%", // Aspect ratio (e.g., 16:9)
                height: 0, // Let height be determined by padding-bottom
              }}
            ></div>
            <button
              className="md:hidden"
              aria-label={isBurgerOpen ? "Close menu" : "Open menu"}
              aria-expanded={isBurgerOpen}
              aria-controls="menu"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                {isBurgerOpen ? (
                  <path
                    d="M6 18L18 6M6 6l12 12"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                ) : (
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4 5C3.44772 5 3 5.44772 3 6C3 6.55228 3.44772 7 4 7H20C20.5523 7 21 6.55228 21 6C21 5.44772 20.5523 5 20 5H4ZM7 12C7 11.4477 7.44772 11 8 11H20C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13H8C7.44772 13 7 12.5523 7 12ZM13 18C13 17.4477 13.4477 17 14 17H20C20.5523 17 21 17.4477 21 18C21 18.5523 20.5523 19 20 19H14C13.4477 19 13 18.5523 13 18Z"
                    fill="currentColor"
                  />
                )}
              </svg>
            </button>
          </div>
          <nav
            className={`flex-grow ${
              isBurgerOpen
                ? "flex burger-open-nav bg-[#FFFBF6] gap-6"
                : "hidden"
            } md:flex md:items-center justify-center md:justify-end absolute md:static top-full right-0 flex-col items-start w-full md:w-auto shadow md:shadow-none pb-8 md:pb-0 md:flex-row`}
          >
            <ul className="w-full flex flex-col md:flex-row justify-center p-4 gap-4">
              <Dropdown
                isOpen={isDropdown1Open && !isBurgerOpen}
                toggle={toggleDropdown1}
                label={t("nav.1")}
                items={[
                  { to: "/features", label: t("nav.1.1") },
                  { to: "/how-it-works", label: t("nav.1.2") },
                  { to: "/mission-and-vision", label: t("nav.1.6") },
                ]}
                isBurgerOpen={isBurgerOpen}
              />
              <Dropdown
                isOpen={isDropdown2Open && !isBurgerOpen}
                toggle={toggleDropdown2}
                label={t("nav.2")}
                items={[
                  { to: "https://blog.camvio.gr/", label: t("nav.2.1") },
                  // { to: "/option1-1", label: t("nav.2.2") },
                  // { to: "/option1-1", label: t("nav.2.3") },
                  // { to: "/option1-1", label: t("nav.2.4") },
                  // { to: "/option1-1", label: t("nav.2.5") },
                ]}
                isBurgerOpen={isBurgerOpen}
              />
            </ul>
            <ul className="flex md:flex-row flex-col md:w-auto w-full md:gap-1 gap-4 items-center mt-4 md:mt-0">
              <li>
                <Link
                  to="https://app.camvio.gr/"
                  className="border-emerald-700 whitespace-nowrap text-emerald-700 border hover:shadow hover:bg-emerald-700 hover:text-white md:px-4 px-24 py-2 rounded-2xl"
                >
                  {t("cta.signin")}
                </Link>
              </li>
              <li>
                <Link
                  to="https://app.camvio.gr/"
                  className=" border-emerald-700 whitespace-nowrap text-emerald-700 border hover:shadow hover:bg-emerald-700 hover:text-white md:px-4  px-24 py-2 rounded-2xl"
                >
                  {t("cta.signup")}
                </Link>
              </li>
              <li>
                <select
                  aria-label="Select Language"
                  value={isEn ? "en" : "gr"}
                  onChange={changeLanguage}
                  className="font-bold border-gray-300 bg-[#FFFBF6] text-gray-800 py-2 px-3 rounded"
                >
                  <option value="en">EN</option>
                  <option value="gr">ΕΛ</option>
                </select>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
}

export default Nav;
