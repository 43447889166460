import React from "react";
import { useTranslation } from "react-i18next";
import Cover from "../imgs/bg-pages.png";

export const Privacy = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-[#FFFBF6]  gap-24 h-full justify-center w-full items-center  text-title ">
      <div
        className="w-full bg-cover bg-green-950 h-[500px] top-0 relative flex items-center px-8 "
        style={{ backgroundImage: `url(${Cover})` }}
      >
        <h2 className="text-5xl font-bold text-white mx-auto flex max-w-7xl w-full">
          {t("privacyPolicy.title")}
        </h2>
      </div>
      <p className=" mx-auto gap-8 max-w-7xl w-full p-8">
        {t("privacyPolicy.last_updated")}
      </p>
      <div className="py-24  flex flex-col mx-auto gap-8 max-w-7xl w-full px-8">
        <section>
          <p className="">{t("privacyPolicy.introduction")}</p>
        </section>

        <section>
          <p>{t("privacyPolicy.company_info")}</p>
        </section>

        <section>
          <p>{t("privacyPolicy.policy_compliance")}</p>
        </section>

        <section>
          <p>{t("privacyPolicy.policy_modification")}</p>
        </section>

        <section>
          <h3 className="text-3xl font-bold pb-4">
            {t("privacyPolicy.responsible_entity_title")}
          </h3>
          <p>{t("privacyPolicy.responsible_entity")}</p>
        </section>

        <section>
          <p>{t("privacyPolicy.data_collection_info")}</p>
        </section>

        <section>
          <h3 className="text-3xl font-bold pb-4">
            {t("privacyPolicy.collected_data.title")}
          </h3>
          <p>{t("privacyPolicy.collected_data.description")}</p>
          <ul className="py-4 list-disc list-inside">
            <li>{t("privacyPolicy.collected_data.list.1")}</li>
            <li>{t("privacyPolicy.collected_data.list.2")}</li>
            <li>{t("privacyPolicy.collected_data.list.3")}</li>
            <li>{t("privacyPolicy.collected_data.list.4")}</li>
            <li>{t("privacyPolicy.collected_data.list.5")}</li>
            <li>{t("privacyPolicy.collected_data.list.6")}</li>
            <li>{t("privacyPolicy.collected_data.list.7")}</li>
          </ul>
          <p>{t("privacyPolicy.collected_data.additional_info")}</p>
        </section>

        <section>
          <h3 className="text-3xl font-bold pb-4">
            {t("privacyPolicy.data_collection_purpose.title")}
          </h3>
          <p>{t("privacyPolicy.data_collection_purpose.description")}</p>
        </section>

        <section>
          <h3 className="text-3xl font-bold pb-4">
            {t("privacyPolicy.data_security.title")}
          </h3>
          <p>{t("privacyPolicy.data_security.description")}</p>
        </section>
        <section>
          <h3 className="text-3xl font-bold pb-4">
            {t("privacyPolicy.data_storage.title")}
          </h3>
          <p>{t("privacyPolicy.data_storage.description")}</p>
        </section>
        <section>
          <h3 className="text-3xl font-bold pb-4">
            {t("privacyPolicy.data_processing_retention.title")}
          </h3>
          <p>{t("privacyPolicy.data_processing_retention.description")}</p>
        </section>

        <section>
          <h3 className="text-3xl font-bold pb-4">
            {t("privacyPolicy.user_rights.title")}
          </h3>
          <p>{t("privacyPolicy.user_rights.description")}</p>

          <ul className="py-4 list-disc list-inside">
            <li>{t("privacyPolicy.user_rights.rights_list.1")}</li>
            <li>{t("privacyPolicy.user_rights.rights_list.2")}</li>
            <li>{t("privacyPolicy.user_rights.rights_list.3")}</li>
            <li>{t("privacyPolicy.user_rights.rights_list.4")}</li>
          </ul>
          <ul className="py-2 list-disc  ml-8">
            <li>{t("privacyPolicy.user_rights.rights_list.5.1")}</li>
            <li>{t("privacyPolicy.user_rights.rights_list.5.2")}</li>
            <li>{t("privacyPolicy.user_rights.rights_list.5.3")}</li>
            <li>{t("privacyPolicy.user_rights.rights_list.5.4")}</li>
          </ul>
          <p> {t("privacyPolicy.user_rights.rights_list.6")}</p>
          <p> {t("privacyPolicy.user_rights.rights_list.7")}</p>

          <p className="pt-4">
            {" "}
            {t("privacyPolicy.user_rights.supervisory_authority")}
          </p>
          <p className="pt-4"> {t("privacyPolicy.user_rights.requests")}</p>
        </section>

        <section>
          <h3 className="text-3xl font-bold pb-4">
            {t("privacyPolicy.contact_info.title")}
          </h3>
          <p>{t("privacyPolicy.contact_info.description")}</p>
        </section>
      </div>
    </div>
  );
};
