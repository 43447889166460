import React, { useEffect } from "react";
import Img1 from "../img/fert.png";

import Img3 from "../img/loss.png";
import Img4 from "../img/planet.png";
import Img5 from "../img/eu.png";

import Leafs from "../svg/leafs.svg";

import AOS from "aos";
import "aos/dist/aos.css";

import { useTranslation, Trans } from "react-i18next";

const content = [
  {
    text: "whatisit.solutions.1",
    image: Img1,
  },
  {
    text: "whatisit.solutions.4",
    image: Img4,
  },
  {
    text: "whatisit.solutions.3",
    image: Img3,
  },
  {
    text: "whatisit.solutions.5",
    image: Img5,
  },
];

const Solutions = () => {
  const { t } = useTranslation();
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="w-full relative lg:px-4 xl:px-24  lg:py-16 flex lg:flex-row  flex-col  gap-8  rounded-2xl text-title">
      <Trans>
        <div className="gap-8 flex flex-col overflow-visible">
          <h2 className="text-4xl font-bold tracking-wide p-8 lg:p-4 relative z-10">
            {t("whatisit.solutions.title")}
          </h2>
          <img
            src={Leafs}
            alt="Leafs"
            className=" absolute right-8 mt-60 md:mt-28 opacity-20"
          />
          <img src={Leafs} alt="Leafs" className=" absolute left-8 bottom-10" />
        
            <div
             className="grid grid-cols-1 px-4 md:grid-cols-2 lg:grid-cols-4 gap-4" data-aos="fade-up" data-aos-anchor-placement="top-center" data-aos-easing="ease-in-sine" data-aos-duration="600">

              {content.map(({ text, image }, index) => (
                <div
                  key={index}
                  className=" h-auto  text-2xl p-4 bg-[#FFFBF6] rounded-2xl flex flex-col gap-8 justify-between  border"
                >
                  <div className="">
                    <img
                      src={image}
                      alt={t(text)}
                      className="h-32 object-cover mb-4 rounded"
                    />
                    <div className="px-2">
                      <Trans
                        i18nKey={text}
                        components={{
                          strong: <strong className="" />,
                        }}
                      />
                    </div>
                  </div>
                  {/* <button className='opacity-20 text-lg hover:opacity-100'>Μάθε περισσότερα</button> */}
                </div>
              ))}
            </div>
          
        </div>
      </Trans>
    </div>
  );
};

export default Solutions;
