import React from "react";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Nav from "./Components/Nav";
import "./i18n";
import Homepage from "./Pages/Homepage";

import { WhatIsCamvio } from "./Pages/features";
import Footer from "./Components/Footer";
import Vision from "./Pages/vision";
import { HowItWorks } from "./Pages/howItWorks";
import { TermsUse } from "./Pages/termsUse";
import { Privacy } from "./Pages/privacy";

function App() {
  return (
    <Router>
      <Nav />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/features" element={<WhatIsCamvio />} />
        <Route path="/mission-and-vision" element={<Vision />} />
        <Route path="/how-it-works" element={<HowItWorks />} />
        <Route path="/terms-of-use" element={<TermsUse />} />
        <Route path="/privacy-policy" element={<Privacy />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
