import React, {  useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";


import Img1 from "../img/fert.png";
import Img2 from "../img/pest.png";
import Img3 from "../img/loss.png";
import Img4 from "../img/planet.png";


import Button from "./ui/moreButton";

import AOS from 'aos';
import 'aos/dist/aos.css';

import webImage from "../img/image-web.png"
import mobImage from "../img/image-mobile.png"


const content = [
  {
    text: "whatisit.frendlyenviroment.1",
    image: Img1,
  },
  {
    text: "whatisit.frendlyenviroment.3",
    image: Img3,
  },
  {
    text: "whatisit.frendlyenviroment.2",
    image: Img2,
  },
  {
    text: "whatisit.frendlyenviroment.4",
    image: Img4,
  },
];





const WhatIs = () => {
  const { t } = useTranslation();
  

  useEffect(() => {
    AOS.init();
  }, [])

  return (
    <div className=" w-φθλλ  lg:px-4 xl:px-24  lg:py-16 flex lg:flex-row flex-col overflow-hidden gap-8 p-8 rounded-2xl text-title">
          {/* <div className="flex flex-col lg:px-4 xl:px-24  lg:py-16 w-full  mx-auto lg:h-min-screen text-text/80  "> */}
<div className="w-full lg:hidden flex justify-center">
         <div className="lg:hidden w-[650px] sm:m-16 xs:m-8">
          <img src={webImage} alt="webCamvio" className=" "/>
          </div>
          <div className="lg:hidden w-[200px] -ml-52 mt-24">
          <img src={mobImage} alt="webCamvio" className="  "/>
          </div>
          </div>
      <div className="flex z-10 lg:border flex-col h-auto lg:w-1/2 gap-4 rounded-xl    bg-[#FFFBF6] md:p-8" data-aos="fade-right" data-aos-anchor-placement="top-center" data-aos-easing="ease-in-sine" data-aos-duration="600">
        <Trans>
       
          <h2 className="text-4xl font-bold  tracking-wide">
            {t("whatisit.frendlyenviroment.title")}
          </h2>
          <div className="flex flex-col subpixel-antialiased py-4 font-medium gap-6  h-full ">
            {content.map(({ text }, index) => (
              <ul key={index} className=" text-2xl list-disc  pl-6  leading-9">
                <li className="text-pretty   rounded-2xl ">
                  <Trans
                    i18nKey={text}
                    components={{
                      1: <br />,
                      strong: <strong className="" />,
                    }}
                  />
                </li>
              </ul>
            ))}
          </div>
          <Button  to="/features" />
        </Trans>
      </div>
      <div className="relative w-1/2 overflow">
    <div className="absolute 2xl:right-20 xl:right-16 lg:right-16 -mt-2 hidden lg:flex lg:w-[600px] xl:w-[700px]" data-aos="fade-left">
        <img src={webImage} alt="webCamvio" className="" />
    </div>
    <div className="absolute 2xl:right-24 xl:right-8 lg:right-2 xl:mt-10 lg:mt-32 right-0 mt-16 hidden lg:flex lg:w-[200px] 2xl:w-[250px]" data-aos="fade-left">
        <img src={mobImage} alt="webCamvio" className="" />
    </div>
</div>


    
    </div>
  );
};

export default WhatIs;