import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Cover from "../imgs/bg-pages.png";

const content = [
  {
    title: "whatiscamvio-inside.1",
    text: "whatiscamvio-inside.1.1",
  },
  {
    title: "whatiscamvio-inside.2",
    text: "whatiscamvio-inside.2.1",
  },
  {
    title: "whatiscamvio-inside.3",
    text: "whatiscamvio-inside.3.1",
  },
  {
    title: "whatiscamvio-inside.4",
    text: "whatiscamvio-inside.4.1",
  },
];

export const WhatIsCamvio = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="bg-[#FFFBF6]  gap-24 h-full justify-center w-full items-center  text-title ">
      <div
        className="w-full bg-cover  bg-green-950 h-[500px] top-0 relative flex items-center px-8 "
        style={{ backgroundImage: `url(${Cover})` }}
      >
        <h2 className="text-5xl font-bold text-white mx-auto flex max-w-7xl w-full">
          {t("whatiscamvio-inside.title")}
        </h2>
      </div>

      <div className="py-24  flex flex-col ">
        {content.map(({ title, text }, index) => (
          <div
            key={index}
            className=" flex flex-col gap-4 mx-auto max-w-7xl  px-8"
          >
            <h2 className="text-3xl font-bold">{t(title)}</h2>
            <p className="text-lg pb-16">{t(text)}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
